<template>
  <v-card flat="" style="background:#f7f7f7;">
    <v-btn outlined class="mb-2" block target="_blank" :href="youtubeURL">Play on YouTube</v-btn>
    <v-responsive :aspect-ratio="16/9">
      <iframe
        id="ytplayer"
        type="text/html"
        :width="width"
        :height="height"
        :src="url"
        frameborder="0"
        allow="autoplay; encrypted-media"
      />
    </v-responsive>
  </v-card>
</template>

<script>
export default {
  name: "playlist",
  data(){
    return {
      player:{},
    }
  },
  props: {
    id: String,
    index: Number,
    width: String,
    height: String,
  },
  watch:{
    index:function(){
       if(window.YT && window.YT.Player){
         window.onYouTubeIframeAPIReady()
    }else
       this.start()
  }
  },
  mounted(){
    this.start()
 
  },

  methods:{
    start(){
    var tag = document.createElement('script');
      tag.id = 'iframe-demo';
      tag.src = 'https://www.youtube.com/iframe_api';
      var firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
      window.onPlayerStateChange=this.onPlayerStateChange
      var me=this
      window.onYouTubeIframeAPIReady=function() {
        
        me.player = new window.YT.Player('ytplayer', {
            events: {
              'onStateChange': window.onPlayerStateChange
            }
        });
      }
      
  },
  onPlayerStateChange(){
    if(this.player && this.player.getPlaylistIndex)
       this.$emit("changeIndex",this.player.getPlaylistIndex()+1)
   
    
  }},
  computed: {
    url: function () {
      return (
        "https://www.youtube.com/embed/?listType=playlist&list=" +
        this.id +
        "&enablejsapi=1&modestbranding=1&color=white&index="+this.index
      );
    },
    youtubeURL: function () {
      return (
        `https://www.youtube.com/playlist?list=${this.id}&index=${this.index}`
      );
    },
  },
};
</script>

<style scoped></style>
