<template>
  <v-container fluid>
    <v-btn block outlined @click="$router.push('/')">Main Menu - القائمة الرئيسية</v-btn>
    <h1 class="mt-4 mb-4">{{name}}</h1>
    <v-autocomplete
      :value="value"
      :items="suras"
      item-value="value"
      item-text="text"
      @change="change"
      outlined
      label="بحث - Search"
      return-object
    ></v-autocomplete>
    <playlist
      @changeIndex="changeIndex"
      class="mt-4"
      :id="$route.params.id"
      :index="index"
      width="100%"
      height="100%"
    />
  </v-container>
</template>

<script>
// @ is an alias to /src
import playlist from "@/components/playlist.vue";
import {suras} from "../data/data"

export default {
  name: "Home",
  components: {
    playlist,
  },
  watch:{
    name:function(){
        this.setPageTitle()
    }
  },  
    head: {
    // To use "this" in the component, it is necessary to return the object through a function
    title: function () {
      return {
        inner: this.name
      }
    },
    meta: [
      // { name: 'description', content: 'My description', id: 'desc' }
    ]
  },
  data(){
    return {
      index:0,
      name:this.$route.params.name,
      suras: [],
      value:false
    }
  },
  methods:{
    setPageTitle(){
document.title=this.$route.params.name==this.name?this.name:this.$route.params.name + "-" +this.name
    },
    changeIndex(id){
      this.value=this.getSura(id)
      this.name=this.value.text
    },
    getSura(id){
      for(var i in this.suras){
        if(id==this.suras[i].value){
          return this.suras[i];
        }
      }
    },
    change(item){
          this.index=parseInt(item.value)
          this.name=item.text
         this.$router.push({path:`/playlist/${this.$route.params.id}/${this.$route.params.name}/${this.$route.params.ar_name}/${item.text}/${item.value}`}) 
  
    }
    
  },
   mounted() {
        this.name=this.$route.params.name
           for(var i in suras){
          const id=parseInt(i)+1;
          let obj ={
            value:id,
            text:id +" - "+suras[i]
          }
          if(!this.value) this.value=obj
          this.suras.push(obj)
            if(this.$route.params.index && this.$route.params.index==id){
              this.name=obj.text
          this.value=obj
          this.index=id
        }
        }
         this.setPageTitle()
        }
      
};
</script>
